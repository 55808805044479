import axios from "axios";
import errors from "../errors";

const noop = () => {};

const axiosUsers = {
  createNewUser: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("user/add", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getCompanies: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get("user/" + data.uid + "/companies");
      if (res.data.success) {
        onSuccess(res.data);
      }
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getCompaniesAsync: async (uid) => {
    const res = await axios.get("user/" + uid + "/companies");
    return res.data;
  },
  getUserById: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get("user/" + data.uid);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getUserAccountInformationById: async (
    data,
    onSuccess = noop,
    onFail = noop,
  ) => {
    try {
      const res = await axios.get("user/" + data.uid + "/account");

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },

  getUserIsCustomer: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get(
        "user/" + data.uid + "/check-is-stripe-customer",
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  setStripeCustomerIdFromSessionId: async (
    data,
    onSuccess = noop,
    onFail = noop,
  ) => {
    try {
      const res = await axios.post("user/setStripeCustomerId", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },

  updateUserById: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("user/" + data.uid + "/update", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
};

export default axiosUsers;
