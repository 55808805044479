import React from "react";
import BasicInput from "./BasicInput";

function AuthLabeledTextInput(props) {
  const onChange = (value) => {
    const re = /^[0-9\b]+$/;
    if (props.numbersOnly) {
      if (value === "" || re.test(value)) {
        props.setValue(value);
      }
    } else {
      props.setValue(value);
    }
  };

  return (
    <>
      <label className={`text-sm my-4 block ${props.className}`}>
        {props.label}
        <BasicInput
          disabled={props.disabled}
          type={props.inputMode || "text"}
          maxLength={props.maxLength}
          onChange={onChange}
          placeholder={props.placeholder}
          value={props.value}
        />
      </label>
    </>
  );
}

export default AuthLabeledTextInput;
