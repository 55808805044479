import React from "react";
import { createBrowserRouter } from "react-router-dom";

// Pages
import AdminAccountPage from "../pages/Admin/Account/AccountPage";
import AdminChangeCompanyPage from "../pages/Admin/Company/ChangeCompanyPage";
import AdminCreateCompanyPage from "../pages/Admin/Company/CreateCompanyPage";
import AdminUpdateCompanyPage from "../pages/Admin/Company/UpdateCompanyPage";
import AdminUpdateCompanyServicesPage from "../pages/Admin/Company/UpdateCompanyServicesPage";
import AdminEmployeeDetailsPage from "../pages/Admin/Employees/EmployeeDetailsPage";
import AdminEmployeePage from "../pages/Admin/Employees/EmployeesPage";
import AdminManageAdminsPage from "../pages/Admin/Employees/ManageAdminsPage";
import AdminJobHistoryDetailsPage from "../pages/Admin/JobHistory/JobHistoryDetailsPage";
import AdminJobHistoryPage from "../pages/Admin/JobHistory/JobHistoryPage";
import AdminBulkImportJobs from "../pages/Admin/Jobs/BulkImportJobs";
import AdminCreateJobPage from "../pages/Admin/Jobs/CreateJobPage";
import AdminJobsPage from "../pages/Admin/Jobs/JobsPage";
import AdminUpdateJobPage from "../pages/Admin/Jobs/UpdateJobPage";
import AdminMaterialsPage from "../pages/Admin/Materials/MaterialsPage";
import AdminReportsPage from "../pages/Admin/Reports/ReportsPage";
import AdminTimeClockPageWrapper from "../pages/Admin/TimeClock/TimeClockPageWrapper";
import TimeRecordPage from "../pages/Admin/TimeClock/TimeRecordPage";
import AccountSetupPage from "../pages/Auth/AccountSetupPage";
import AboutPage from "../pages/LandingPage/AboutPage";
import AdminLoginPage from "../pages/LandingPage/AdminLoginPage";
import HomePage from "../pages/LandingPage/HomePage";

// import AdminPurchaseAddOnsPage from '../pages/Admin/Subscription/AdminPurchaseAddOnsPage';
import AdminWrapper from "../pages/Admin/AdminWrapper";
import ActiveRouteDetailsPage from "../pages/Admin/Routes/ActiveRouteDetails";
import ActiveRoutesPage from "../pages/Admin/Routes/ActiveRoutes";
import AdminCreateRoutePage from "../pages/Admin/Routes/CreateRoutePage";
import AdminRoutesPage from "../pages/Admin/Routes/RoutesPage";
import AdminUpdateRoutePage from "../pages/Admin/Routes/UpdateRoutePage";
import TimeClockEmployeePage from "../pages/Admin/TimeClock/TimeClockEmployeePage";
import CreateFirstCompany from "../pages/Auth/CreateFirstCompany";
import GetStartedPage from "../pages/Auth/GetStartedPage";
import BillingWrapper from "../pages/Billing/BillingWrapper";
import ProductsPage from "../pages/Billing/ProductsPage";
import DeveloperBroadcastPushNotification from "../pages/Developer/BroadcastPushNotification";
import ErrorPage from "../pages/ErrorPage";
import ContactUsPage from "../pages/LandingPage/ContactUsPage";
import DocsPage from "../pages/LandingPage/DocsPage";
import FAQPage from "../pages/LandingPage/FAQPage";
import FeaturesPage from "../pages/LandingPage/FeaturesPage";
// import HomePage from "../pages/LandingPage/HomePage";
import LandingPage from "../pages/LandingPage/LandingPage";
import PricingPage from "../pages/LandingPage/PricingPage";
import QuotePage from "../pages/LandingPage/QuotePage";

// New Landing Page

// Routing
import PrivateRoutes from "./PrivateRoutes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/contact",
        element: <ContactUsPage />,
      },
      {
        path: "/pricing",
        element: <PricingPage />,
      },
      {
        path: "/pricing/quote",
        element: <QuotePage />,
      },
      {
        path: "/features",
        element: <FeaturesPage />,
      },
      {
        path: "/faq",
        element: <FAQPage />,
      },
      {
        path: "/docs",
        element: <DocsPage />,
      },
      {
        path: "/admin-login",
        element: <AdminLoginPage />,
      },
      {
        path: "/checkout/products",
        element: <ProductsPage />,
      },
      {
        path: "/checkout/billing",
        element: <BillingWrapper />,
      },
      {
        path: "/get-started",
        element: <GetStartedPage />,
      },
      {
        path: "/account-setup",
        element: <AccountSetupPage />,
      },
      {
        path: "/create-first-company",
        element: <CreateFirstCompany />,
      },
    ],
  },
  {
    path: "/admin",
    element: (
      <PrivateRoutes>
        <AdminWrapper />
      </PrivateRoutes>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/admin",
        element: <AdminRoutesPage />,
      },
      {
        path: "/admin/active-routes",
        element: <ActiveRoutesPage />,
      },
      {
        path: "/admin/active-routes/:routeId",
        element: <ActiveRouteDetailsPage />,
      },
      {
        path: "/admin/account",
        element: <AdminAccountPage />,
      },
      {
        path: "/admin/user/company",
        element: <AdminChangeCompanyPage />,
      },
      {
        path: "/admin/user/company/create",
        element: <AdminCreateCompanyPage />,
      },
      {
        path: "/admin/user/company/:companyId",
        element: <AdminUpdateCompanyPage />,
      },
      {
        path: "/admin/company/services",
        element: <AdminUpdateCompanyServicesPage />,
      },
      {
        path: "/admin/employee",
        element: <AdminEmployeePage />,
      },
      {
        path: "/admin/employee/:employeeUid",
        element: <AdminEmployeeDetailsPage />,
      },
      {
        path: "/admin/employee/manage-admins",
        element: <AdminManageAdminsPage />,
      },
      {
        path: "/admin/material/",
        element: <AdminMaterialsPage />,
      },
      {
        path: "/admin/property",
        element: <AdminJobsPage />,
      },
      {
        path: "/admin/timeclock",
        element: <AdminTimeClockPageWrapper />,
      },
      {
        path: "/admin/timeclock/:record_id",
        element: <TimeRecordPage />,
      },
      {
        path: "/admin/timeclock/employee/:user_id",
        element: <TimeClockEmployeePage />,
      },
      {
        path: "/admin/excel_import",
        element: <AdminBulkImportJobs />,
      },
      // {
      //     path: '/admin/purchase/addons',
      //     element: <AdminPurchaseAddOnsPage />,
      // },
      {
        path: "/admin/property/create",
        element: <AdminCreateJobPage />,
      },
      {
        path: "/admin/property/:jobId",
        element: <AdminUpdateJobPage />,
      },
      {
        path: "/admin/property/history",
        element: <AdminJobHistoryPage />,
      },
      {
        path: "/admin/property/history/:jobHistoryId",
        element: <AdminJobHistoryDetailsPage />,
      },
      {
        path: "/admin/report/",
        element: <AdminReportsPage />,
      },
      {
        path: "/admin/route",
        element: <AdminRoutesPage />,
      },
      {
        path: "/admin/route/create/:season",
        element: <AdminCreateRoutePage />,
      },
      {
        path: "/admin/route/:routeId",
        element: <AdminUpdateRoutePage />,
      },
      {
        path: "/admin/developer/broadcast-push-notification",
        element: <DeveloperBroadcastPushNotification />,
      },
    ],
  },
]);

export default router;
