import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

const DefaultButtonStyles =
  "text-full-white text-center max-w-[500px] w-full rounded-md py-3 bg-gradient-to-r from-[rgba(5,103,203,1)] to-[rgba(1,171,234,1)] disabled:from-[rgba(40,45,53,1)] disabled:to-[rgba(40,45,53,1)] disabled:opacity-50 disabled:border-solid disabled:border disabled:border-[#50565F] outline-none";

function SquareButton({
  onClick,
  disabled,
  loading,
  loaderColor,
  loaderSize,
  label,
  children,
  className,
}) {
  return (
    <button
      onClick={(e) => {
        if (!loading) onClick(e);
      }}
      disabled={disabled}
      className={`${DefaultButtonStyles} ${className ?? ""}`}
    >
      {loading ? (
        <PulseLoader
          color={loaderColor || "#fffafa"}
          size={loaderSize || 5}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        label ?? children
      )}
    </button>
  );
}

export default SquareButton;
export { DefaultButtonStyles };
