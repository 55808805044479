import React, { useState } from "react";

// Components
import StandardButton from "../Buttons/StandardButton";

// Resources
import EllipsesBlue from "../../resources/ellipses-blue.svg";

const HeaderBar = (props) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  return (
    <div className="flex flex-row place-items-center justify-between w-full h-20 border-b-2 border-snow-primary px-10">
      <div className="text-3xl font-medium text-snow-primary">
        {props.title}
      </div>
      <div className="flex justify-end">
        {props.button?.enabled ? (
          <StandardButton
            color={props.button.color || "blue"}
            disabled={props.button.disabled || false}
            label={props.button.label}
            onClick={props.button.onClick}
          />
        ) : null}
        {props.buttons?.map((item, key) => (
          <div className="ml-4 print:hidden" key={key}>
            <StandardButton
              color={item.color || "blue"}
              disabled={item.disabled || false}
              label={item.label}
              loading={item.loading}
              onClick={item.onClick}
            />
          </div>
        ))}
      </div>

      {props.dropdown?.enabled ? (
        <div
          className="relative"
          onClick={() => setDisplayDropdown(!displayDropdown)}
        >
          <img alt="menu" className="w-8 hover:opacity-60" src={EllipsesBlue} />
          {displayDropdown ? (
            <>
              {/* Overlay to close on clickoff */}
              <div
                className=" fixed left-0 w-full h-full z-10"
                onClick={() => setDisplayDropdown(false)}
              ></div>
              <ul className="absolute right-0 top-6 list-none bg-snow-white border-[1.5px] border-snow-primary rounded-lg p-2 z-20 drop-shadow-lg">
                {props.dropdown.options.map((option, key) => (
                  <li
                    className={`mx-3 my-1 hover:opacity-60 whitespace-nowrap ${
                      option.color === "red"
                        ? "text-snow-red"
                        : option.color === "green"
                          ? "text-snow-green"
                          : "text-snow-blue-3"
                    }`}
                    key={key}
                    onClick={() => {
                      // Run passed in operation
                      option.operation();

                      // Close dropdown
                      setDisplayDropdown(false);
                    }}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default HeaderBar;
