import React, { useEffect, useState } from "react";
import EmailIcon from "../../resources/landing-page/email.svg";
import PhoneIcon from "../../resources/landing-page/phone.svg";
import SnowScapeLogoWithWhiteText from "../../resources/logo/snowscape-logo-horizontal-with-white-text.svg";
import axiosContact from "../../services/axios/contact";
import SquareButton from "../../components/Buttons/SquareButton";
import WithSnowFlakes from "../../components/LandingPage/WithSnowFlakes";
import BasicInput from "../../components/Inputs/BasicInput";
import BasicTextArea from "../../components/Inputs/BasicTextArea";
import SplitFormSmall from "../../components/SplitFormSmall";

const SuccessMessage = (
  <div className="flex flex-col flex-1 items-center justify-center">
    <div className="rounded-xl border-2 border-snow-green py-20 px-10 drop-shadow-xl">
      <div className="font-bold text-3xl text-center text-snow-green pb-6">
        Message Successfully Sent
      </div>
      <div className="font-bold text-xl text-center text-snow-green">
        We're excited to hear from you and will get back to you as quickly as
        possible!
      </div>
    </div>
  </div>
);
const ContactUsPage = () => {
  const [state, setState] = useState({
    email: "",
    name: "",
    message: "",
  });
  const [emailValid, setEmailValid] = useState(false);
  const [successfullySent, setSuccessfullySent] = useState(false);

  useEffect(() => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(emailPattern.test(state.email));
  }, [state.email]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    const data = await axiosContact.sendContactUsEmail({
      type: "contact-us",
      ...state,
    });

    if (data.success) {
      setSuccessfullySent(true);
    }
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  const form = !successfullySent ? (
    <form className="flex flex-col">
      <BasicInput
        name="name"
        type="text"
        placeholder="John Doe"
        value={state.name}
        onChange={(value) => setState({ ...state, name: value })}
      />
      <BasicInput
        name="email"
        type="email"
        placeholder="your-email@gmail.com"
        value={state.email}
        onChange={(value) => setState({ ...state, email: value })}
      />
      <BasicTextArea
        name="message"
        placeholder="Type your message here..."
        value={state.message}
        onChange={(value) => setState({ ...state, message: value })}
        rows={5}
      />
      <SquareButton
        label="Send Message"
        color="blue-gradient"
        onClick={handleSendMessage}
        className="max-w-none"
        disabled={!state.email || !state.name || !state.message || !emailValid}
      />
    </form>
  ) : (
    SuccessMessage
  );

  return (
    <WithSnowFlakes>
      <div className="flex flex-col w-full items-center px-4 py-20 overflow-scroll ">
        <div className="w-full max-w-[1200px]">
          <div className="text-snow-white font-bold text-5xl font-catamaran text-center">
            Contact Us
          </div>
          <SplitFormSmall
            leftContent={
              <>
                <div className="text-snow-white text-2xl text-left">
                  We'd love to hear from you! Send us a message or email
                  directly, and we'll get back to you ask quickly as we can!
                </div>
                <div className="mt-10">
                  <img
                    alt="snowscape-logo"
                    className="w-44"
                    src={SnowScapeLogoWithWhiteText}
                  />
                  <div className="mt-4">
                    <div className="flex items-center gap-4">
                      <img
                        src={EmailIcon}
                        alt="email-icon"
                        className="w-6 h-6"
                      />
                      <a
                        className="text-snow-white"
                        href="mailto:info@mysnowscape.com"
                      >
                        info@mysnowscape.com
                      </a>
                    </div>
                    <div className="flex items-center gap-4 mt-4">
                      <img
                        src={PhoneIcon}
                        alt="phone-icon"
                        className="w-6 h-6"
                      />
                      <a className="text-snow-white" href="tel:2482909029">
                        (248) 290-9029
                      </a>
                    </div>
                  </div>
                </div>
              </>
            }
          >
            {form}
          </SplitFormSmall>
        </div>
      </div>
    </WithSnowFlakes>
  );
};

export default ContactUsPage;
export { SuccessMessage };
