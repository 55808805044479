import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import axiosCompanies from "../../services/axios/companies";
import { updateUser } from "../../store/reducers/currentUserSlice";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import NotificationModal from "../Modals/NotificationModal";
import axiosUsers from "../../services/axios/users";
import flash from "../../services/flash";
import { useAuthState } from "react-firebase-hooks/auth";
import firebaseAuth from "../../services/firebase/firebaseAuth";

/**
 * Until the app supports switching companies gracefully across pages, we will require whitelisting here
 * to allow a page to not be redirected to the home when the user switches companies.
 */
const routeToNavigateToAfterSwitchingCompany = (url) => {
  // return null;
  // eslint-disable-next-line no-unreachable
  let newRoute = "/admin/route";
  const pathToRoute = [
    {
      regex: /admin\/property\/history.*/,
      to: "/admin/property/history",
    },
    {
      regex: /admin\/property.*/,
      to: "/admin/property/",
    },
    {
      regex: /admin\/timeclock.*/,
      to: "/admin/timeclock",
    },
    {
      regex: /admin\/employee\/manage-admins.*/,
      to: "/admin/employee/manage-admins",
    },
    {
      regex: /admin\/employee.*/,
      to: "/admin/employee",
    },
    {
      regex: /admin\/report.*/,
      to: "/admin/report",
    },
    {
      regex: /admin\/material.*/,
      to: "/admin/material",
    },
    {
      regex: /admin\/company\/services.*/,
      to: "/admin/company/services",
    },
    {
      regex: /admin\/active-routes.*/,
      to: "/admin/active-routes",
    },
  ];
  for (const check of pathToRoute) {
    if (check.regex.test(url)) {
      newRoute = check.to;
      break;
    }
  }
  return newRoute == url ? null : newRoute;
};

const SwitchCompanyDropdown = ({ currentUser }) => {
  const [user] = useAuthState(firebaseAuth.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyOptions, setCompanyOptions] = useState([]);

  // For Modals:
  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayUnpaidCompanyModal, setDisplayUnpaidCompanyModal] =
    useState(false);

  // FIXME: need to test modals
  const { pathname } = useLocation();
  const handleChangeCompany = async (companyId) => {
    // Check if company user is changing to is paid
    axiosCompanies.checkIfPaid(
      {
        companyId,
        uid: currentUser.uid,
      },
      async (paymentData) => {
        setIsOwner(false);

        if (paymentData.paid) {
          dispatch(
            updateUser({
              ...currentUser,
              currentCompanyId: companyId,
              isPaid: true,
              isPaidUpdatedAt: Date.now(),
            }),
          );

          const targetRoute = routeToNavigateToAfterSwitchingCompany(pathname);
          console.log("targetRoute", targetRoute);
          if (targetRoute) {
            navigate(targetRoute);
          }
          return;
        } else if (
          // Not paid and we are the company owner
          paymentData.companyOwner.uid === currentUser.uid
        ) {
          setIsOwner(true);
          setDisplayUnpaidCompanyModal(true);
        } else {
          // Not paid, but someone else is the company owner
          setDisplayUnpaidCompanyModal(true);
        }
      },
      () => {
        flash.error("Unable to switch companies - please contact support.");
      },
    );
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await axiosUsers.getCompaniesAsync(currentUser.uid);
      setCompanyOptions(
        data.userCompanies.map((c) => ({
          label: c.companyName,
          value: c.companyId,
        })),
      );
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    // Need to wait for a token before we send any requests
    if (currentUser.uid) {
      fetchData();
    }
  }, [currentUser, fetchData, user]);

  return (
    <>
      <Select
        isLoading={loading}
        onChange={(option) => handleChangeCompany(option.value)}
        options={companyOptions}
        placeholder="Select Company"
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#829399",
          }),
        }}
        value={companyOptions.find(
          (o) => o.value == currentUser.currentCompanyId,
        )}
      />
      <NotificationModal
        button={{
          color: "blue",
          label: "Okay",
          onClick: () => {
            setDisplayUnpaidCompanyModal(false);
            if (isOwner) navigate("/admin/account");
            setIsOwner(false);
          },
        }}
        open={displayUnpaidCompanyModal}
        subtitle={
          isOwner
            ? "The company you tried to access doesn't appear to be licensed. Please add them to your licensed companies list on your Account Page to continue."
            : "The company you tried to access doesn't appear to be licensed. Please contact the company manager to setup their subscription."
        }
        title="Subscription Notice"
      />
    </>
  );
};
export default connect((state) => ({ currentUser: state.currentUser }))(
  SwitchCompanyDropdown,
);
export { SwitchCompanyDropdown };
