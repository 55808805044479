import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

// Component
import AuthComponent from "../../components/Auth/AuthComponent";

// Services
import firebaseAuth from "../../services/firebase/firebaseAuth";
import SplitForm from "../../components/SplitForm";

const GetStartedPage = () => {
  const navigate = useNavigate();

  const [user, loading] = useAuthState(firebaseAuth.auth);

  useEffect(() => {
    if (loading) return;
    if (user) {
      // TODO: Get user from server
      // Already user
      // navigate('/admin');
      // New User
      navigate("/account-setup");
    }
  }, []); // user, loading, navigate

  let leftContent = (
    <p className="text-xl">
      Welcome to SnowScape! We're excited to have you join us. To get started,
      create an account using your phone number. You'll receive a text message
      with a code shortly.
    </p>
  );

  return (
    <>
      <SplitForm leftContent={leftContent}>
        <div className="grid h-full">
          <div className="text-full-white place-self-center">
            <AuthComponent
              title="Create an Account"
              subtext="Already have an account? Sign In."
              subtextUrl="/admin-login"
            />
          </div>
        </div>
      </SplitForm>
      <div className="flex flex-col justify-center items-center px-4 md:px-20">
        <div className="mx-auto mt-12 md:mt-20 text-2xl font-light font-snow-blue-3 max-w-sm sm:max-w-5xl">
          Welcome to SnowScape! We're excited to have you join us. To get
          started, create an account using your phone number. You'll receive a
          text message with a code shortly.
        </div>
      </div>
      <div className="mt-12"></div>
    </>
  );
};

export default GetStartedPage;
