import React from "react";
import PricingCard from "../pages/LandingPage/components/PricingCard";
import { Link } from "react-router-dom";

const Pricing = ({ onPlanSelect, buttonText }) => {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 lg:gap-4 mt-10">
        <PricingCard
          price="free"
          title="Han Solo"
          details={["1 Company", "1 User"]}
          subtext="Add extra users for $11.99 / user"
          onClick={() => onPlanSelect("hansolo-free-sub")}
          buttonText={buttonText}
        />
        <PricingCard
          price="89"
          title="Team"
          recommended
          details={["1 Company", "20 Users"]}
          subtext="Add extra users for $11.99 / user"
          onClick={() => onPlanSelect("team-sub")}
          buttonText={buttonText}
        />
        <PricingCard
          price="179"
          title="Squad"
          details={["2 Companies/ Subcontractors", "100 Users"]}
          subtext="Add extra users for $11.99 / user"
          onClick={() => onPlanSelect("squad-sub")}
          buttonText={buttonText}
        />
        <PricingCard
          price="269"
          title="Fleet"
          details={["5 Companies/ Subcontractors", "300 Users"]}
          subtext="Add extra users for $11.99 / user"
          onClick={() => onPlanSelect("fleet-sub")}
          buttonText={buttonText}
        />
      </div>
      <div className="mx-auto mt-4 text-2xl font-light mb-8 text-center">
        Get started with a <b>7 day free trial</b> today!
        <br />
        Not seeing a tier that's right for you?{" "}
        <Link to="/pricing/quote" className="underline">
          Get a quote
        </Link>
        .
      </div>
      <p className="max-w-[800px] mx-auto text-sm">
        All our plans support adding extra users for $11.99 / user / month or
        extra companies at $49.99 / company / month. After signing up, you can
        modify your subscription to add extra users / companies - or just shoot
        us an email and we will do it for you!
      </p>
    </>
  );
};

export default Pricing;
