import React, { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import axiosStripe from "../../services/axios/stripe";
import Pricing from "../../components/Pricing";
import SquareButton from "../../components/Buttons/SquareButton";

const ProductsPage = (props) => {
  const navigate = useNavigate();

  const openStripeCheckoutPage = (lookupKey) => {
    axiosStripe.purchaseSubscription(
      {
        products: [
          {
            lookup_key: lookupKey,
            quantity: 1,
            type: "subscription",
          },
        ],
      },
      (data) => {
        // Redirect to checkout page
        window.location.href = data.sessionUrl;
      },
    );
  };

  return (
    <div className="flex flex-col items-center overflow-scroll px-4 md:px-20 pb-20">
      <div className="flex flex-col justify-center w-full max-w-5xl text-center lg:text-left mt-12 text-2xl font-light text-full-white">
        {props.currentUser.firstName
          ? `Welcome, ${props.currentUser.firstName}! `
          : null}
        We know that each of our customers is different. That's why we have
        designed multiple tiers to best fit your personal needs.
        <div className="mt-6 text-2xl font-bold font-snow-blue-primary max-w-5xl">
          Please choose the tier you'd like to subscribe to:
        </div>
      </div>
      <Pricing
        buttonText="Checkout"
        onPlanSelect={(plan) => {
          if (plan === "hansolo-free-sub") {
            alert(
              "The Han Solo tier is free. You will be asked for credit card information, but you will not be charged unless you choose to upgrade your account to a paid tier.",
            );
          }
          openStripeCheckoutPage(plan);
        }}
      />
      <SquareButton
        onClick={() => navigate("/admin/user/company/?openJoinCompanyDialog=1")}
      >
        Join Existing Company
      </SquareButton>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(ProductsPage);
