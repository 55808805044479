import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/LandingPage/Footer";
import AppleAppStoreLogo from "../../resources/app-store/apple-app-store-logo.svg";
import GooglePlayStoreLogo from "../../resources/app-store/google-play-store-logo.svg";
import GearIcon from "../../resources/landing-page/gear.svg";
import MergeIcon from "../../resources/landing-page/merge.svg";
import ShieldIcon from "../../resources/landing-page/shield.svg";
import FeaturesCard from "./components/FeaturesCard";
import LandingPageGradientButton from "./components/LandingPageGradientButton";

const FeaturesPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col w-full items-center px-4 py-20 overflow-scroll ">
        <div className="w-full max-w-[1200px]">
          <div className="text-snow-white font-bold text-5xl text-center font-catamaran">
            Our Features
          </div>
          <div className="max-w-md mx-auto mt-20">
            <LandingPageGradientButton
              label="Get Started With A 7 Day Free Trial"
              color="blue-gradient"
              onClick={() => {
                navigate("/get-started");
              }}
              fontSize="lg"
              arrow
            />
          </div>
          <div className="flex gap-4 justify-center max-w-md mx-auto mt-20">
            <a href="https://play.google.com/store/apps/details?id=com.trayl.dev.snow">
              <img
                src={GooglePlayStoreLogo}
                alt="google-play-store-logo"
                className="w-44 my-6 md:w-40 hover:opacity-70"
              />
            </a>
            <a href="https://apps.apple.com/us/app/snowscape-management/id6443944347">
              <img
                src={AppleAppStoreLogo}
                alt="apple-app-store-logo"
                className="w-44 my-6 md:w-40 hover:opacity-70"
              />
            </a>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-14 lg:gap-4 mt-20">
            <FeaturesCard
              details={[
                "Protection from slip and fall liability",
                "Reduce exposure to slip and falls with picture documentation of all services rendered (time stamped)",
                "Unlimited job sites",
                "Ability to grow with you and your staff",
                "Affordable service packages for all company sizes",
                "Service reports to protect from billing disputes",
                "Service reports that automatically record the weather conditions",
              ]}
              title="Protect"
              icon={ShieldIcon}
            />
            <FeaturesCard
              details={[
                "Create and customize routes year round",
                "Unlimited service reports emailed upon completion of a job",
                "Unlimited picture documentation",
                "Job history report generating",
                "Tracking materials used",
                "Tracking of time spent at each job",
                "Payroll reports",
                "TimeClock management",
                "Customizable notes for each job",
                "Customizable site maps for each job",
                "Crews can flag issues as urgent",
              ]}
              title="Manage"
              icon={GearIcon}
              featured
            />
            <FeaturesCard
              details={[
                "Everything in one place",
                "Simple and user friendly",
                "Month to month payment terms",
                "Eliminate paperwork",
                "Real time reporting of services being performed",
                "Site maps to specify job site parameters",
                "Ability to track subcontractors",
                "A customizable report generator to give you all the data you need to bid jobs properly and bill accurately",
                "Option to send service report directly to clients",
              ]}
              title="Streamline"
              icon={MergeIcon}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesPage;
