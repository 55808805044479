import React from "react";
import Pricing from "../../components/Pricing";
import { useNavigate } from "react-router-dom";
const PricingPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col w-full items-center px-4 py-20 overflow-scroll ">
        <div className="w-full max-w-[1200px]">
          <div className="text-snow-white font-bold text-5xl text-center font-catamaran">
            Our Pricing
          </div>
          <Pricing
            buttonText="Get Started"
            onPlanSelect={() => {
              navigate("/get-started");
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PricingPage;
