import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

// Component
import AuthComponent from "../../components/Auth/AuthComponent";

// Services
import firebaseAuth from "../../services/firebase/firebaseAuth";
import SplitForm from "../../components/SplitForm";

const AdminLoginPage = () => {
  const navigate = useNavigate();

  const [user, loading] = useAuthState(firebaseAuth.auth);

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/admin");
  }, [user, loading, navigate]);

  return (
    <SplitForm>
      <div className="grid h-full">
        <div className="text-full-white place-self-center py-20">
          <AuthComponent
            subtext="Don't have an account? Sign Up."
            subtextUrl="/get-started"
            title="Login"
            subtitle="Welcome back! Please enter your details."
          />
        </div>
      </div>
    </SplitForm>
  );
};

export default AdminLoginPage;
